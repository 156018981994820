<!--
 * @Author: 冉桂精 156189868@qq.com
 * @Date: 2023-10-26 20:30:00
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-10-27 09:13:34
 * @FilePath: /dataview-viewer-test/src/views/TestVideo.vue
 * @Description: l
-->
<template>
  <div class="test-video">
    <el-input v-model="cgUrl"></el-input>
    <el-button type="primary" @click="isShow = !isShow">测试</el-button>
    <el-button type="primary" @click="onPlay">播放</el-button>
    <H265Player v-if="isShow"
      :styleObj="styleObj"
      :url="cgUrl"
      :autoPlay="autoPlay"
      ref="player"
    ></H265Player>
  </div>
</template>

<script>
import H265Player from '@/custom-component/functionCom/h265/Index.vue'
  export default {
    name: 'TestVideo',
    components: {
      H265Player
    },
    data() {
      return {
        isShow: false,
        styleObj: {
          width: 800,
          height: 600
        },
        cgUrl: '',
        autoPlay: true
      }
    },
    created() {
      this.cgUrl = 'http://192.168.2.187:8080/hdl/live/test.flv';
      // this.cgUrl = 'http://192.168.2.187:8080/fmp4/live/test.mp4';
      // this.cgUrl = 'ws://192.168.2.187:8080/jessica/live/test.flv';
    },
    methods: {
      onPlay() {
        this.$refs.player.onPlay();
      }
    }
  }
</script>

<style lang="less" scoped>

</style>